import React from "react"
import Grid from "@material-ui/core/Grid"
import Fab from "@material-ui/core/Fab"
import AddIcon from "@material-ui/icons/Add"
import CircularProgress from "@material-ui/core/CircularProgress"
import { green } from "@material-ui/core/colors"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

interface BlogLoadMoreProps {
  show: boolean
  loading: boolean
  clickHandler: Function
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadMoreWrapper: {
      marginBottom: theme.spacing(3),
    },
    loadMoreBtn: {
      backgroundColor: theme.palette.info.main,
    },
    fabProgress: {
      color: green[500],
      top: -6,
      left: -6,
      zIndex: 1,
      position: "absolute",
    },
  })
)

export default function BlogLoadMore(props: BlogLoadMoreProps) {
  const { show, loading, clickHandler } = props

  const classes = useStyles()

  if (show) {
    return (
      <Grid
        container
        item
        xs={12}
        justify="center"
        className={classes.loadMoreWrapper}
      >
        <Fab
          className={classes.loadMoreBtn}
          disabled={loading}
          onClick={clickHandler}
          aria-label="load more"
        >
          <AddIcon />
          {loading && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </Fab>
      </Grid>
    )
  } else {
    return null
  }
}

import React from "react"
import { Chip, createStyles, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"

interface Tag {
  tagPK: string
}

interface TagListProps {
  tags: {
    items: Tag[]
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagChip: {
      margin: theme.spacing(0.5),
      "&:hover": {
        cursor: "pointer",
        fontWeight: "bold",
      },
    },
  })
)

export default function TagList(props: TagListProps) {
  const classes = useStyles()

  if (!props.tags || props.tags.items.length === 0) {
    return null
  }

  const tags = props.tags.items.map((tag: Tag, idx: number) => {
    return (
      <Chip
        label={tag.tagPK}
        key={idx}
        color="secondary"
        className={classes.tagChip}
        onClick={() => {
          navigate(`/tag/${tag.tagPK}`)
        }}
      />
    )
  })

  return <div>{tags}</div>
}

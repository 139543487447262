import React from "react"
import { BlogForList } from "../utils/types"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import TagList from "./tag-list"
import Button from "@material-ui/core/Button"
import NotesIcon from "@material-ui/icons/Notes"
import Divider from "@material-ui/core/Divider"
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles"
import green from "@material-ui/core/colors/green"
import useMediaQuery from "@material-ui/core/useMediaQuery"

interface BlogListProps {
  blogs: BlogForList[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    blogActions: {
      textAlign: "right",
    },
    tagContainer: {
      display: "flex",
      flexWrap: "wrap",
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(2),
        justifyContent: "flex-end",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    tagChip: {
      margin: theme.spacing(0.5),
    },
    fabProgress: {
      color: green[500],
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
    titleLink: {
      color: "inherit",
    },
  })
)

export default function BlogList(props: BlogListProps) {
  const { blogs } = props

  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <React.Fragment>
      {blogs.map((blog: BlogForList, idx: number) => {
        const created = formatDistanceToNow(new Date(blog.SK), {
          addSuffix: true,
        })

        return (
          <Grid item xs={12} key={idx}>
            <Paper elevation={3} className={classes.paper}>
              <Grid container>
                <Grid item sm={12} md={8}>
                  <Typography variant="h4" component="h2" gutterBottom={true}>
                    <Link
                      to={`/blog/${blog.path}`}
                      className={classes.titleLink}
                    >
                      {blog.title}
                    </Link>
                  </Typography>
                  <Typography variant="body1">{blog.blurb}</Typography>
                </Grid>
                {!isSmall && (
                  <Grid container item md={4} alignContent="space-between">
                    <Grid item xs={12} className={classes.tagContainer}>
                      <TagList tags={blog.tags} />
                    </Grid>
                    <Grid item xs={12} className={classes.blogActions}>
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<NotesIcon />}
                        component={Link}
                        to={`/blog/${blog.path}`}
                      >
                        Read more
                      </Button>
                      <br />
                      <Typography variant="overline">{created}</Typography>
                    </Grid>
                  </Grid>
                )}
                {isSmall && (
                  <Grid item xs={12} className={classes.tagContainer}>
                    <TagList tags={blog.tags} />
                  </Grid>
                )}
              </Grid>
              {isSmall && (
                <div>
                  <Divider className={classes.divider} />
                  <Grid
                    container
                    item
                    xs={12}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="overline">{created}</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<NotesIcon />}
                        component={Link}
                        to={`/blog/${blog.path}`}
                      >
                        Read more
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
          </Grid>
        )
      })}
    </React.Fragment>
  )
}
